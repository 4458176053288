body {
  /* background-color: #3498db; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.container {
  margin: 0px auto;
  background: #fff;
  padding: 0;
  border-radius: 7px;
}



.chat-feed{
  height: 340px;
  overflow-x:hidden;
}


.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 40px;
}

.settings-tray {
  background: #eee;
  padding: 10px 15px;
  border-radius: 7px;
}
.settings-tray .no-gutters {
  padding: 0;
}
.settings-tray--right {
  float: right;
}
.settings-tray--right i {
  margin-top: 10px;
  font-size: 25px;
  color: grey;
  margin-left: 14px;
  transition: 0.3s;
}
.settings-tray--right i:hover {
  color: #74b9ff;
  cursor: pointer;
}

.search-box {
  background: #fafafa;
  padding: 10px 13px;
}
.search-box .input-wrapper {
  background: #fff;
  border-radius: 40px;
}
.search-box .input-wrapper i {
  color: grey;
  margin-left: 10px;
  padding: 10px;
  vertical-align: middle;
}

input {
  border: none;
  border-radius: 30px;
  width: 80%;
}
input::placeholder {
  color: #e3e3e3;
  font-weight: 300;
  margin-left: 20px;
}
input:focus {
  outline: none;
}

.friend-drawer {
  padding: 10px 15px;
  display: flex;
  vertical-align: baseline;
  background: #fff;
  transition: 0.3s ease;
}
.friend-drawer--grey {
  background: #eee;
}
.friend-drawer .text {
  margin-left: 12px;
  width: 70%;
}
.friend-drawer .text h6 {
  margin-top: 6px;
  margin-bottom: 0;
}
.friend-drawer .text p {
  margin: 0;
}
.friend-drawer .time {
  color: grey;
}
.friend-drawer--onhover:hover {
  background: #74b9ff;
  cursor: pointer;
}
.friend-drawer--onhover:hover p,
.friend-drawer--onhover:hover h6,
.friend-drawer--onhover:hover .time {
  color: #fff !important;
}

hr {
  margin: 5px auto;
  width: 60%;
}

.chat-bubble {
  padding: 10px 14px;
  background: #eee;
  margin: 10px 30px;
  border-radius: 9px;
  position: relative;
  animation: fadeIn 1s ease-in;
}
.chat-bubble:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom: 0;
  margin-top: -10px;
}
.chat-bubble--left:after {
  left: 0;
  border-right-color: #eee;
  border-left: 0;
  margin-left: -20px;
}
.chat-bubble--right:after {
  right: 0;
  border-left-color: #74b9ff;
  border-right: 0;
  margin-right: -20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.offset-md-9 .chat-bubble {
  background: #74b9ff;
  color: #fff;
}

.chat-box-tray {
  background: #eee;
  display: flex;
  /* align-items:baseline; */
  padding: 10px 15px;
  align-items: center;
  /* margin-top: 250px; */
  bottom: 0;
}
.chat-box-tray input {
  margin: 0;
  padding: 6px 2px;
}
.chat-box-tray i {
  color: grey;
  font-size: 30px;
  vertical-align: middle;
}
.chat-box-tray i:last-of-type {
  margin-left: 25px;
}

.chat-main-div {
width: 100%;
height: 100vh;
background-color: #404040;
}
.chat-screen-div {
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
}
.chat-screen-1 {
display: flex;
justify-content: space-between;
width: 70%;
margin-top: 20px;
}
.chat-screen-2 {
display: flex;
justify-content: space-between;
width: 70%;
margin-top: 20px;
}
.chat-screen-1-h5 {
background-color: #282828;
width: 600px;
min-height: 40px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 20px;
color: white;
margin: 0px;
padding: 0px;
}
.chat-screen-2-h5 {
background-color: #282828;
width: 600px;
min-height: 40px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 20px;
color: white;
margin: 0px;
padding: 0px;
}
.chat-screen-footer-div {
position: fixed;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
/* margin-top: 20px; */
gap: 200px;
bottom: 0;
padding-bottom: 20px;
}
.chat-message-input {
background-color: #282828;
height: 30px;
width: 50%;
border-radius: 30px;
padding: 10px;
border: none;
color: white;
}

/* @media (min-width: 320px) and (max-width: 480px) {
.chat-box-tray input {
  width: 300px;
}
} */